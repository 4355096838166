import Wrapper from '../../components/Wrapper';
import React from 'react';
import {
	EditionValidationType,
	ItemDto,
	SerieItemAdminDto,
	ValidationState,
} from '../../types/global';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Link, useNavigate } from 'react-router-dom';
import ButtonDialog from '../../components/Widgets/ButtonDialog';
import { api } from '../../_services';
import ItemAddLink from './ItemAddLink';
import { useQuery } from 'react-query';
import { Reponse } from '../../utils/request';
import { ValidationBloc } from '../../components/ValidationBloc';

type DetailCompositionsProps = {
	media: ItemDto;
	onChange: () => void;
};

type AdminItemCompositionDto = {
	itemId: number;
	childItemId: number;
	title: string;
	userId?: number;
	userIdState: ValidationState;
};

export const DetailCompositions = ({ media, onChange }: DetailCompositionsProps) => {
	const compositions = useQuery<Reponse<AdminItemCompositionDto[]>>(
		['item', 'composition', media.id],
		() =>
			api.call('GET', `/admin/item/${media.id}/compositions/`) as Promise<
				Reponse<AdminItemCompositionDto[]>
			>,
	);

	const onChangeLocal = () => {
		void compositions.refetch();
		onChange();
	};

	return (
		<>
			<Wrapper sectionHeading='Compositions'>
				<Table>
					{compositions?.data?.data.map((value) => (
						<TableRow key={value.itemId}>
							<TableCell>
								<Link to={`/items/detail/${value.itemId}`}>{value.title}</Link>
							</TableCell>
							<TableCell>{value.userId}</TableCell>
							<TableCell>
								{value.userId && (
									<ValidationBloc
										state={value.userIdState}
										onValidate={() =>
											void api
												.call(
													'PUT',
													`/admin/item/${value.itemId}/composition/${
														value.childItemId
													}/accept/${value.userId ?? 0}/`,
												)
												.then(() => onChangeLocal())
										}
										onRefuse={() =>
											void api
												.call(
													'PUT',
													`/admin/item/${value.itemId}/composition/${
														value.childItemId
													}/refuse/${value.userId ?? 0}/`,
												)
												.then(() => onChangeLocal())
										}
									/>
								)}
								&nbsp;
								<ButtonDialog
									text='Supprimer'
									onValid={() =>
										void api
											.call(
												'DELETE',
												`/admin/item/${value.itemId}/composition/${
													value.childItemId
												}/user/${value.userId ?? '0'}/`,
											)
											.then(() => onChangeLocal())
									}
									confirmBtnCancel='Annuler'
									confirmBtnValid='Supprimer'
									confirmTitle='Souhaitez vous supprimer ce lien ?'
								/>
							</TableCell>
						</TableRow>
					))}
				</Table>
				&nbsp;
				<ItemAddLink
					media={media}
					onAdd={(newid) => {
						void api
							.call('PUT', `/item/${media.id}/composition/${newid}/`)
							.then(() => onChangeLocal());
					}}
				/>
			</Wrapper>
		</>
	);
};
