import React from 'react';
import Wrapper from '../../components/Wrapper';
import Button from '@mui/material/Button';
import { api } from '../../_services';

const Sql = () => {
	return (
		<>
			<Wrapper sectionHeading='Actions'>
				<Button
					variant='contained'
					disableElevation
					onClick={() =>
						api.call(
							'PUT',
							'/admin/cmd/calculateStateYear/?year=' + (new Date().getFullYear() - 1),
						)
					}>
					CalculateStats
				</Button>
				&nbsp;
				<Button
					variant='contained'
					disableElevation
					onClick={() => api.call('PUT', '/admin/cmd/opalebd/')}>
					s Refetch OpaleBD
				</Button>
				&nbsp;
				<Button
					variant='contained'
					disableElevation
					onClick={() => api.call('PUT', '/admin/cmd/moccamNews/')}>
					Refetch moccamNews
				</Button>
				&nbsp;
				<Button
					variant='contained'
					disableElevation
					onClick={() => api.call('PUT', '/admin/cmd/fnacEditions/')}>
					Refetch fnacEditions
				</Button>
				&nbsp;
				<Button
					variant='contained'
					disableElevation
					onClick={() => api.call('PUT', '/admin/cmd/author/knowledge/')}>
					Refetch knowledge
				</Button>
				&nbsp;
				<Button
					variant='contained'
					disableElevation
					onClick={() => api.call('PUT', '/admin/cmd/amazon/refreshNextReleaseImages/')}>
					Refetch Amazon next Images
				</Button>
			</Wrapper>
			<p>
				-- vérifier de temps en temps les doublons dans les sources, c&apos;est pas normal
				et faut fusionner souvent <br />
				select ms_src_id , ms_import_id , count(*) from media_src ms group by ms_src_id ,
				ms_import_id having count(*) {'>'} 1
			</p>
			<p>
				-- pour les auteurs : virer les roles non utile
				<br />
				update media_authors ma set ma_usr_id_state = 2 where ma_author_role in( 95, 6, 91,
				94, 95, 97, 100) and ma_usr_id_state =0
			</p>
			<p>
				-- pour les auteurs : accepter directement les auteurs déja avec un autre roles
				validé sur le meme item
				<br />
				update media_authors ma set ma_usr_id =null, ma_notified =true where ma_usr_id_state
				= 0 and ma_usr_id is not null and exists ( select 1 from media_authors ma1 where
				ma.ma_author_id = ma1 .ma_author_id and ma1.ma_usr_id is null )
			</p>
			<p>
				-- pour les editions refuser auto les prix
				<br />
				update media_editions_users set meu_price_state =2 where meu_price_state = 0
			</p>
			<p>
				-- slow query
				<br />
				select (mean_exec_time + mean_plan_time)::int as mean_time, mean_exec_time::int,
				mean_plan_time::int, calls, query from pg_stat_statements --where -- calls {'>'} 1
				-- and userid = 99999 order by mean_time desc limit 50;
			</p>
			<p>
				-- Reduce your workload
				<br />
				select (total_exec_time + total_plan_time)::int as total_time, total_exec_time::int,
				total_plan_time::int, mean_exec_time::int, calls, query from pg_stat_statements
				order by total_time desc limit 50;
			</p>
			<p>
				-- Reduce your IO
				<br />
				select shared_blks_hit + shared_blks_read + shared_blks_dirtied +
				shared_blks_written + local_blks_hit + local_blks_read + local_blks_dirtied +
				local_blks_written + temp_blks_read + temp_blks_written as total_buffers,
				(total_exec_time + total_plan_time)::int as total_time, calls, shared_blks_hit as
				sbh, shared_blks_read as sbr, shared_blks_dirtied as sbd, shared_blks_written as
				sbw, local_blks_hit as lbh, local_blks_read as lbr, local_blks_dirtied as lbd,
				local_blks_written as lbw, temp_blks_read as tbr, temp_blks_written as tbr, query
				from pg_stat_statements order by total_buffers desc limit 50;
			</p>
		</>
	);
};
export default Sql;
