import React from 'react';
import { Box, Table, TableBody } from '@mui/material';
import {
	EditionMedia,
	EditionUserMedia,
	EditionValidationType,
	ValidationState,
} from '../../../types/global';
import {
	useEditions,
	useEditionsUsers,
	useMutationDeleteEditionsUsers,
	useMutationEditionsValidate,
} from './use-editions';
import Loader from '../../../components/Loader';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { ValidationBloc } from '../../../components/ValidationBloc';
import ImageList from '../series/ImageList';
import { itemService } from '../../../_services';
import ButtonDialog from '../../../components/Widgets/ButtonDialog';
import EansAdd from '../../items/EansAdd';
import EansSearch from '../../items/EansSearch';
import { useItem } from '../../items/use-item';

interface EditionEditProps {
	itemId: number;
}

export default function EditionEdit({ itemId }: EditionEditProps) {
	const { data: media, refetch: refetchMedia } = useItem(itemId);
	const {
		data: editions,
		isLoading: isEditionLoading,
		refetch: refetchEdition,
	} = useEditions(itemId);
	const {
		data: editionsUsers,
		isLoading: isEditionUserLoading,
		refetch: refecthEditionUser,
	} = useEditionsUsers(itemId);

	const mutationDeleteEditionsUsers = useMutationDeleteEditionsUsers();
	const mutationEditionsValidate = useMutationEditionsValidate();

	if (isEditionLoading || isEditionUserLoading) {
		return <Loader />;
	}

	const validate = (
		ean: string,
		userId: number,
		type: EditionValidationType,
		state: ValidationState,
	) => {
		mutationEditionsValidate.mutate(
			{
				itemId,
				ean,
				userId,
				type,
				state,
			},
			{
				onSuccess: () => {
					void refetchEdition();
					void refecthEditionUser();
				},
			},
		);
	};

	const printEditionUser = (edt: EditionMedia, edtUser: EditionUserMedia) => {
		const isNewOne = edt.ean == '' && edtUser.userIdState != ValidationState.REFUSED;
		const isRefuseOne = edt.ean == '' && edtUser.userIdState == ValidationState.REFUSED;

		const showIsbnValidation =
			!isNewOne &&
			!isRefuseOne &&
			edtUser.isbn.length > 0 &&
			edtUser.isbn.filter((edtUisbn) => edt.isbn.includes(edtUisbn)).length <
				edtUser.isbn.length;

		const showTitleValidation =
			!isNewOne && !isRefuseOne && edtUser.title && edtUser.title != edt.title;
		const showPriceValidation =
			!isNewOne && !isRefuseOne && edtUser.price && edtUser.price != edt.price;
		const showEditorValidation =
			!isNewOne &&
			!isRefuseOne &&
			edtUser.editor?.name &&
			edtUser.editor?.name != edt.editor?.name;
		const showDateValidation =
			!isNewOne && !isRefuseOne && edtUser.date && edtUser.date != edt.date;
		const showLangValidation =
			!isNewOne && !isRefuseOne && edtUser.lang && edtUser.lang != edt.lang;
		const showWeightValidation =
			!isNewOne && !isRefuseOne && edtUser.weight && edtUser.weight != edt.weight;
		const showSizeValidation =
			!isNewOne && !isRefuseOne && edtUser.size && edtUser.size != edt.size;
		const showPageValidation =
			!isNewOne && !isRefuseOne && edtUser.page && edtUser.page != edt.page;
		const showImageValidation =
			!isNewOne &&
			!isRefuseOne &&
			edtUser.image &&
			(!edt.image || edtUser.image.id != edt.image.id);

		return (
			<TableRow
				className={`${isNewOne ? 'isNewOne' : ''} ${isRefuseOne ? 'isRefuseOne' : ''}`}>
				<TableCell>{edtUser.ean}</TableCell>
				<TableCell>
					{edtUser.isbn.join(',')}
					<br />
					{showIsbnValidation && (
						<ValidationBloc
							state={edtUser.isbnState}
							onValidate={() =>
								validate(
									edtUser.ean,
									edtUser.userId,
									EditionValidationType.ISBN,
									ValidationState.ACCEPTED,
								)
							}
							onRefuse={() =>
								validate(
									edtUser.ean,
									edtUser.userId,
									EditionValidationType.ISBN,
									ValidationState.REFUSED,
								)
							}
						/>
					)}
				</TableCell>
				<TableCell>
					{edtUser.title}
					<br />
					{showTitleValidation && (
						<ValidationBloc
							state={edtUser.titleState}
							onValidate={() =>
								validate(
									edtUser.ean,
									edtUser.userId,
									EditionValidationType.TITLE,
									ValidationState.ACCEPTED,
								)
							}
							onRefuse={() =>
								validate(
									edtUser.ean,
									edtUser.userId,
									EditionValidationType.TITLE,
									ValidationState.REFUSED,
								)
							}
						/>
					)}
					{edtUser.comment}
				</TableCell>
				<TableCell>
					{edtUser.price}
					<br />
					{showPriceValidation && (
						<ValidationBloc
							state={edtUser.priceState}
							onValidate={() =>
								validate(
									edtUser.ean,
									edtUser.userId,
									EditionValidationType.PRICE,
									ValidationState.ACCEPTED,
								)
							}
							onRefuse={() =>
								validate(
									edtUser.ean,
									edtUser.userId,
									EditionValidationType.PRICE,
									ValidationState.REFUSED,
								)
							}
						/>
					)}
				</TableCell>
				<TableCell>
					{edtUser.editor?.name}
					<br />
					{showEditorValidation && (
						<ValidationBloc
							state={edtUser.editorState}
							onValidate={() =>
								validate(
									edtUser.ean,
									edtUser.userId,
									EditionValidationType.EDITOR,
									ValidationState.ACCEPTED,
								)
							}
							onRefuse={() =>
								validate(
									edtUser.ean,
									edtUser.userId,
									EditionValidationType.EDITOR,
									ValidationState.REFUSED,
								)
							}
						/>
					)}
				</TableCell>
				<TableCell>
					{edtUser.date}
					<br />
					{showDateValidation && (
						<ValidationBloc
							state={edtUser.dateState}
							onValidate={() =>
								validate(
									edtUser.ean,
									edtUser.userId,
									EditionValidationType.DATE,
									ValidationState.ACCEPTED,
								)
							}
							onRefuse={() =>
								validate(
									edtUser.ean,
									edtUser.userId,
									EditionValidationType.DATE,
									ValidationState.REFUSED,
								)
							}
						/>
					)}
				</TableCell>
				<TableCell>
					{edtUser.lang}
					<br />
					{showLangValidation && (
						<ValidationBloc
							state={edtUser.langState}
							onValidate={() =>
								validate(
									edtUser.ean,
									edtUser.userId,
									EditionValidationType.LANG,
									ValidationState.ACCEPTED,
								)
							}
							onRefuse={() =>
								validate(
									edtUser.ean,
									edtUser.userId,
									EditionValidationType.LANG,
									ValidationState.REFUSED,
								)
							}
						/>
					)}
				</TableCell>
				<TableCell>
					{edtUser.weight}
					<br />
					{showWeightValidation && (
						<ValidationBloc
							state={edtUser.weightState}
							onValidate={() =>
								validate(
									edtUser.ean,
									edtUser.userId,
									EditionValidationType.WEIGHT,
									ValidationState.ACCEPTED,
								)
							}
							onRefuse={() =>
								validate(
									edtUser.ean,
									edtUser.userId,
									EditionValidationType.WEIGHT,
									ValidationState.REFUSED,
								)
							}
						/>
					)}
				</TableCell>
				<TableCell>
					{edtUser.size}
					<br />
					{showSizeValidation && (
						<ValidationBloc
							state={edtUser.sizeState}
							onValidate={() =>
								validate(
									edtUser.ean,
									edtUser.userId,
									EditionValidationType.SIZE,
									ValidationState.ACCEPTED,
								)
							}
							onRefuse={() =>
								validate(
									edtUser.ean,
									edtUser.userId,
									EditionValidationType.SIZE,
									ValidationState.REFUSED,
								)
							}
						/>
					)}
				</TableCell>
				<TableCell>
					{edtUser.page}
					<br />
					{showPageValidation && (
						<ValidationBloc
							state={edtUser.pageState}
							onValidate={() =>
								validate(
									edtUser.ean,
									edtUser.userId,
									EditionValidationType.PAGE,
									ValidationState.ACCEPTED,
								)
							}
							onRefuse={() =>
								validate(
									edtUser.ean,
									edtUser.userId,
									EditionValidationType.PAGE,
									ValidationState.REFUSED,
								)
							}
						/>
					)}
				</TableCell>
				<TableCell>
					<div>
						{edtUser.image && (
							<ImageList height={120} width={90} image={edtUser.image} />
						)}
					</div>
					{showImageValidation && (
						<ValidationBloc
							state={edtUser.imageState}
							onValidate={() =>
								validate(
									edtUser.ean,
									edtUser.userId,
									EditionValidationType.IMAGE,
									ValidationState.ACCEPTED,
								)
							}
							onRefuse={() =>
								validate(
									edtUser.ean,
									edtUser.userId,
									EditionValidationType.IMAGE,
									ValidationState.REFUSED,
								)
							}
						/>
					)}
				</TableCell>
				<TableCell>{edtUser.userId}</TableCell>
				<TableCell>
					{edt.ean == '' && (
						<ValidationBloc
							state={edtUser.userIdState}
							onValidate={() =>
								itemService.addEan(itemId, edtUser).then(() => {
									void refetchEdition();
									void refecthEditionUser();
								})
							}
							onRefuse={() =>
								validate(
									edtUser.ean,
									edtUser.userId,
									EditionValidationType.USR,
									ValidationState.REFUSED,
								)
							}
						/>
					)}

					<ButtonDialog
						text='Supprimer'
						onValid={() =>
							mutationDeleteEditionsUsers.mutate(
								{
									itemId: itemId,
									ean: edtUser.ean,
									userId: edtUser.userId,
								},
								{
									onSuccess: () => {
										void refetchEdition();
										void refecthEditionUser();
									},
								},
							)
						}
						confirmBtnCancel='Annuler'
						confirmBtnValid='Supprimer'
						confirmTitle='Souhaitez vous supprimer ce code à barres ?'
						confirmDesc={edt.ean}
					/>
				</TableCell>
			</TableRow>
		);
	};

	function getMainImage(edt: EditionMedia) {
		if (media?.data?.images && media.data.images.length > 0) {
			const imgDto = media.data.images[0];

			return (
				<ButtonDialog
					text='get'
					onValid={() => {
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						const data: EditionUserMedia = {
							ean: edt.ean,
							image: imgDto,
						};

						void itemService.addEan(itemId, data).then(() => {
							void refetchEdition();
							void refecthEditionUser();
						});
					}}
					confirmBtnCancel='Annuler'
					confirmBtnValid='Appliquer'
					confirmTitle='Souhaitez vous faire prendre la version version principale ?'
					confirmDesc=''
				/>
			);
		}
		return null;
	}

	return (
		<Box sx={{ overflow: 'auto' }}>
			<Table size='small' className='table-responsive'>
				<TableHead>
					<TableRow>
						<TableCell>Ean</TableCell>
						<TableCell>Isbn</TableCell>
						<TableCell>Titre</TableCell>
						<TableCell>Prix</TableCell>
						<TableCell>Editeur</TableCell>
						<TableCell>Date</TableCell>
						<TableCell>Lang</TableCell>
						<TableCell>Poid</TableCell>
						<TableCell>Taille</TableCell>
						<TableCell>Page</TableCell>
						<TableCell>Image</TableCell>
						<TableCell>User</TableCell>
						<TableCell>Action</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{editions?.data?.map((edt) => {
						return (
							<>
								<TableRow className={'tr-local'}>
									<TableCell>{edt.ean}</TableCell>
									<TableCell>{edt.isbn}</TableCell>
									<TableCell>{edt.title}</TableCell>
									<TableCell>{edt.price}</TableCell>
									<TableCell>{edt.editor?.name}</TableCell>
									<TableCell>{edt.date}</TableCell>
									<TableCell>{edt.lang}</TableCell>
									<TableCell>{edt.weight}</TableCell>
									<TableCell>{edt.size}</TableCell>
									<TableCell>{edt.page}</TableCell>
									<TableCell>
										{edt.image && (
											<>
												<ImageList
													height={120}
													width={90}
													image={edt.image}
												/>
												<br />
												<ButtonDialog
													text='set'
													onValid={() =>
														void itemService
															.updateMainImage(
																itemId,
																edt.image?.id ?? 0,
															)
															.then(() => {
																void refetchMedia();
																void refetchEdition();
																void refecthEditionUser();
															})
													}
													confirmBtnCancel='Annuler'
													confirmBtnValid='Appliquer'
													confirmTitle='Souhaitez vous faire de cette image la version principale ?'
													confirmDesc=''
												/>
												&nbsp;
												{getMainImage(edt)}
											</>
										)}
									</TableCell>
									<TableCell></TableCell>
									<TableCell>
										<ButtonDialog
											text='Supprimer'
											color='error'
											onValid={() =>
												itemService.deleteEan(itemId, edt.ean).then(() => {
													void refetchEdition();
													void refecthEditionUser();
												})
											}
											confirmBtnCancel='Annuler'
											confirmBtnValid='Supprimer'
											confirmTitle='Souhaitez vous supprimer ce code à barres ?'
											confirmDesc={edt.ean}
										/>
									</TableCell>
								</TableRow>
								{editionsUsers?.data
									.filter((edtUser) => edt.ean === edtUser.ean)
									.map((edtUser) => printEditionUser(edt, edtUser))}
							</>
						);
					})}
					{editionsUsers?.data
						.filter(
							(edtUser) =>
								editions?.data?.findIndex((edt) => edt.ean === edtUser.ean) == -1,
						)
						.map((edtUser) =>
							printEditionUser(
								{
									ean: '',
									date: '',
									title: '',
									isbn: [],
									price: 0,
								},
								edtUser,
							),
						)}
				</TableBody>
			</Table>
			<Box sx={{ my: 1 }}>
				<EansAdd
					id={itemId}
					onUpd={() => {
						void refetchEdition();
						void refecthEditionUser();
					}}
				/>
				&nbsp;
				<EansSearch
					id={itemId}
					title=''
					onUpd={() => {
						void refetchEdition();
						void refecthEditionUser();
					}}
				/>
			</Box>
		</Box>
	);
}
